import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delayActionAnimation } from '@t12/characters/constants/delay-animation.constant';
import { CharactersAttackActions } from '@t12/characters/store/actions/attack/characters-attack.actions';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getCharacterByCode,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { DialogDbService } from '@t12/dialog/services/dialog-db/dialog-db.service';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { EventDbService } from '@t12/events/services/event-db.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { delayResetBlackoutIn } from '@t12/world/constants/delay-reset-blackout-in.constant';
import { delayResetBlackoutOut } from '@t12/world/constants/delay-reset-blackout-out.constant';
import { WorldActions } from '@t12/world/store/actions/world/world-actions';
import {
  catchError,
  concat,
  concatMap,
  delay,
  from,
  map,
  of,
  switchMap,
  take,
  timer,
  withLatestFrom,
} from 'rxjs';
import { EventsActions } from '../actions/events.actions';
import {
  getCurrentEventStep,
  getEventId,
  getEventPlayerQuestId,
} from '../selectors/events.selectors';

@Injectable()
export class EventsEffects {
  private _setEventSteps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.setEventSteps),
      switchMap(() => [
        HudDisplayActions.closeHudEvent(),
        EventsActions.startEvent(),
      ]),
    ),
  );

  private _startEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEvent),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, id]) => [
        WorldActions.setTime({ time: DayTime.BLACKOUT_OUT }),
        CharactersMoveActions.setCanMove({
          id,
          kind: CharacterKind.PLAYER,
          canMove: false,
        }),
      ]),
    ),
  );

  private _handleEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEvent, EventsActions.nextEvent),
      withLatestFrom(this._store.select(getCurrentEventStep)),
      switchMap(([action, eventStep]) =>
        (action.type === EventsActions.startEvent.type
          ? of(eventStep).pipe(delay(delayResetBlackoutOut))
          : of(eventStep)
        ).pipe(
          map((step) => {
            switch (step?.type) {
              case 'weather':
                return EventsActions.startEventWeather({ event: step });
              case 'dialog':
                return EventsActions.startEventDialog({ event: step });
              case 'character':
                return EventsActions.startEventCharacter({ event: step });
              case 'popup':
                return EventsActions.startEventPopup({ event: step });
              case 'quest':
                return EventsActions.startEventQuest({ event: step });
              default:
                return EventsActions.endEvent();
            }
          }),
        ),
      ),
    ),
  );

  private _startEventWeather$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventWeather),
      switchMap(({ event }) =>
        concat(
          of(
            ...(event.dayTime
              ? [WorldActions.setTime({ time: event.dayTime })]
              : []),
            ...(event.weather
              ? [WorldActions.setWeather({ weather: event.weather })]
              : []),
          ),
          of(EventsActions.nextEvent()).pipe(delay(delayResetBlackoutOut)),
        ),
      ),
    ),
  );

  private _startEventDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventDialog),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ event }, playerId]) =>
        this._store.select(getCharacterByCode(event.characterCode)).pipe(
          take(1),
          switchMap((npc) =>
            this._dialogDbService.getDialog(playerId, event.dialogCode).pipe(
              take(1),
              map((textsDialog) =>
                DialogActions.startConversationSuccess({
                  textsDialog,
                  npc,
                }),
              ),
            ),
          ),
          catchError(() => of(EventsActions.startEventDialogFailed())),
        ),
      ),
    ),
  );

  private _startEventCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventCharacter),
      switchMap(({ event }) =>
        this._store.select(getCharacterByCode(event.characterCode)).pipe(
          take(1),
          switchMap((character) =>
            concat(
              from(event.actions).pipe(
                concatMap((action) => {
                  if (action.type === 'attack') {
                    const { attackKind } = action;
                    return timer(delayActionAnimation).pipe(
                      map(() =>
                        CharactersAttackActions.attack({
                          id: character.id,
                          characterKind: character.kind,
                          attackKind,
                        }),
                      ),
                    );
                  } else if (action.type === 'move' && 'looking' in action) {
                    const { looking } = action;
                    return timer(delayActionAnimation).pipe(
                      map(() =>
                        CharactersMoveActions.setLooking({
                          id: character.id,
                          kind: character.kind,
                          looking,
                        }),
                      ),
                    );
                  } else if (action.type === 'move' && 'direction' in action) {
                    const { direction } = action;
                    return timer(delayActionAnimation).pipe(
                      map(() =>
                        CharactersMoveActions.move({
                          id: character.id,
                          kind: character.kind,
                          direction,
                          force: true,
                        }),
                      ),
                    );
                  } else if (action.type === 'focus') {
                    return timer(delayActionAnimation).pipe(
                      map(() =>
                        CharactersActions.setCharacterFocus({
                          code: event.characterCode,
                        }),
                      ),
                    );
                  }
                }),
              ),
              of(EventsActions.nextEvent()).pipe(delay(delayActionAnimation)),
            ),
          ),
        ),
      ),
    ),
  );

  private _startEventPopup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventPopup),
      map(({ event }) => HudDisplayActions.showHud({ name: event.hudCode })),
    ),
  );

  private _startEventQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventQuest),
      concatMap(({ event }) => [
        QuestActions.addQuest({
          questCode: event.createQuest,
          npcCode: event.npcCode,
        }),
        EventsActions.nextEvent(),
      ]),
    ),
  );

  private _endEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.endEvent),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getEventId),
        this._store.select(getEventPlayerQuestId),
      ),
      switchMap(([_, playerId, eventId, playerQuestId]) =>
        this._eventDbService
          .updateEventStatusQuest(playerId, eventId, playerQuestId)
          .pipe(
            take(1),
            concatMap(() =>
              concat(
                of(WorldActions.setTime({ time: DayTime.BLACKOUT_IN })),
                of(CharactersActions.setCharacterFocus({})),
                of(EventsActions.emptyEvent()).pipe(
                  delay(delayResetBlackoutIn),
                ),
                of(WorldActions.loadWorldCharacters()),
                of(
                  EventsActions.endEventSuccess(),
                  CharactersMoveActions.setCanMove({
                    id: playerId,
                    kind: CharacterKind.PLAYER,
                    canMove: true,
                  }),
                  HudDisplayActions.showHud({ name: 'barInfos' }),
                ),
              ),
            ),
            catchError(() => of(EventsActions.endEventFailed())),
          ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialogDbService: DialogDbService,
    private readonly _eventDbService: EventDbService,
    private readonly _store: Store,
  ) {}
}
