import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters/characters.actions';
import { CharactersFightActions } from '@t12/characters/store/actions/fight/characters-fight.actions';
import {
  getPlayerName,
  getPlayerID,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatTab } from '@t12/common/chat/enums/chat-tab.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalPlayerActions } from '@t12/player/store/actions/local-player.actions';
import { QuestDbService } from '@t12/quest/services/quest-db/quest-db.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import {
  areQuestGoalsDone,
  getQuestsInfos,
  getQuestsInfosByCode,
} from '@t12/quest/store/selectors/quest.selectors';
import {
  catchError,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class QuestEffects {
  private _getQuests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.getQuests),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._questDbService.getQuestsPlayer(playerId).pipe(
          take(1),
          map((questsInfos) => QuestActions.setQuests({ questsInfos })),
          catchError(() => of(QuestActions.getQuestsFailed())),
        ),
      ),
    ),
  );

  private _getQuestsFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.getQuestsFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les quêtes du joueur',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _addQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.addQuest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ questCode, npcCode }, playerId]) =>
        this._questDbService.initQuestPlayer(playerId, questCode, npcCode).pipe(
          take(1),
          map((questInfos) => {
            return QuestActions.addQuestSuccess({
              questInfos,
              npcCode,
            });
          }),
        ),
      ),
    ),
  );

  private _addQuestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.addQuestSuccess),
      switchMap(({ questInfos, npcCode }) =>
        this._store.select(areQuestGoalsDone(questInfos.code)).pipe(
          take(1),
          tap(() => {
            this._notificationService.addNotification(
              'quest',
              `Nouvelle quête ajoutée.`,
            );
          }),
          withLatestFrom(this._store.select(getPlayerName)),
          switchMap(([isDone, name]) => [
            ChatActions.addChatLog({
              tab: ChatTab.ACTION,
              name,
              text: `${name} a débuté la quête "${questInfos.name}".`,
              kind: ChatLogKind.Log,
            }),
            CharactersActions.setQuestIcon({
              codeCharacter: npcCode,
              questIcon: isDone ? 'done' : 'inProgress',
            }),
          ]),
        ),
      ),
    ),
  );

  private _updateGoalsNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.updateGoals),
      filter(({ amount }) => amount > 0),
      withLatestFrom(this._store.select(getQuestsInfos)),
      mergeMap(([{ goalKind, entityCode }, questsInfos]) => {
        questsInfos.forEach(({ goals }) =>
          goals
            .filter(
              (goal) =>
                goal.entityCode === entityCode && goal.kind === goalKind,
            )
            .forEach(({ amount, amountTotal, name }) => {
              this._notificationService.addNotification(
                'quest',
                `${amount}/${amountTotal} : ${name}`,
              );
            }),
        );

        const doneQuests = questsInfos.filter((quest) =>
          quest.goals
            .filter((goal) => !goal.optional)
            .every((goal) => goal.amount === goal.amountTotal),
        );
        const actions: Action[] = [];

        if (
          doneQuests.length > 0 &&
          doneQuests.some((doneQuest) => !!doneQuest.validateNextQuest)
        ) {
          actions.push(QuestActions.getQuests());
        }
        actions.push(
          ...doneQuests.map((questInfo) =>
            CharactersActions.setQuestIcon({
              codeCharacter: questInfo.npcCode,
              questIcon: 'done',
            }),
          ),
        );

        return actions;
      }),
    ),
  );

  private _validateQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.validateQuest),
      switchMap(({ questCode }) =>
        this._store.select(getQuestsInfosByCode(questCode)).pipe(take(1)),
      ),
      switchMap((questInfos) =>
        this._store.select(areQuestGoalsDone(questInfos.code)).pipe(
          take(1),
          filter((goalsDone) => goalsDone),
          map(() => QuestActions.validateQuestSuccess({ questInfos })),
        ),
      ),
    ),
  );

  private _validateQuestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.validateQuestSuccess),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([{ questInfos }, player]) => {
        const { xp, gold, rewardItems, name, npcCode, goals, code } =
          questInfos;

        return this._questDbService.validateQuest(player.id, code).pipe(
          take(1),
          tap(() => {
            this._notificationService.addNotification(
              'quest',
              `Quête terminée : ${name}`,
            );
          }),
          map(() => {
            const itemsRemoved = goals
              .filter((goal) => goal.kind === 'collect')
              .map((goal) => ({
                code: goal.entityCode,
                amount: goal.amountTotal,
              })) as Item[];

            const rewardText =
              rewardItems
                ?.map(({ name, amount }) => `"${name}" x${amount}`)
                .join(', ') || '';
            const message = `${player.name} a terminé la quête "${name}" et reçu ${gold ?? 0} G, ${xp ?? 0} XP${rewardText ? ` et ${rewardText}.` : '.'}`;

            return [
              QuestActions.validateQuestReward({
                xp,
                gold,
                items: rewardItems,
              }),
              itemsRemoved?.length
                ? InventoryActions.removeItemsInInventory({
                    items: itemsRemoved,
                  })
                : null,
              ChatActions.addChatLog({
                tab: ChatTab.ACTION,
                name: player.name,
                text: message,
                kind: ChatLogKind.Log,
              }),
              QuestActions.getAvailableQuest({ npcCode }),
              QuestActions.removeQuest({ questCode: code }),
            ].filter(Boolean);
          }),
          catchError((error) => [of(QuestActions.validateQuestFailed(error))]),
        );
      }),
      mergeMap((actions) => actions),
    ),
  );

  private _validateQuestReward$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.validateQuestReward),
      switchMap(({ xp, gold, items }) =>
        [
          gold ? LocalPlayerActions.addGold({ gold }) : null,
          xp
            ? CharactersFightActions.rewardXp({
                xp,
                notification: false,
              })
            : null,
          items?.length
            ? InventoryActions.addItemsInInventory({
                items,
              })
            : null,
        ].filter(Boolean),
      ),
    ),
  );

  private _validateQuestFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.validateQuestFailed),
      switchMap((error) => {
        const actions: Action[] = [DialogActions.endConversation()];

        switch (error.error.message) {
          case 'NOT_ENOUGH_SPACE':
            actions.push(QuestActions.validateQuestFailNotEnoughSpace());
            break;
          default:
            actions.push(QuestActions.validateQuestFailApiError());
            break;
        }

        return of(...actions);
      }),
    ),
  );

  private _validateQuestFailApiError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.validateQuestFailApiError),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _validateQuestFailNotEnoughSpace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.validateQuestFailNotEnoughSpace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre inventaire.",
          );
        }),
      ),
    { dispatch: false },
  );

  private _getAvailableQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.getAvailableQuest),
      withLatestFrom(this._store.select(getPlayer)),
      switchMap(([{ npcCode }, player]) =>
        this._questDbService.getAvailableQuestsNpc(player.id, npcCode).pipe(
          take(1),
          map((questInfos) =>
            CharactersActions.setQuestIcon({
              codeCharacter: questInfos?.npcCode || npcCode,
              questIcon: !!questInfos
                ? questInfos.lvl > player.lvl
                  ? 'unAvalaible'
                  : 'available'
                : undefined,
            }),
          ),
          catchError(() => of(QuestActions.getAvailableQuestFailed())),
        ),
      ),
    ),
  );

  private _abandonQuest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(QuestActions.abandonQuest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ questsInfos }, playerId]) =>
        this._questDbService.abandonQuest(playerId, questsInfos.code).pipe(
          take(1),
          switchMap(() => [
            QuestActions.removeQuest({ questCode: questsInfos.code }),
            CharactersActions.setQuestIcon({
              codeCharacter: questsInfos.npcCode,
              questIcon: 'available',
            }),
          ]),
          catchError(() => of(QuestActions.abandonQuestFail())),
        ),
      ),
    ),
  );

  private _abandonQuestFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(QuestActions.abandonQuestFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous ne pouvez pas abandonner une quête de la trame principale',
          );
        }),
      ),
    { dispatch: false },
  );
  d;

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _questDbService: QuestDbService,
    private readonly _store: Store,
  ) {}
}
